import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import providerService from 'services/providers.service';
import ArmyRespite from './ArmyRespite';
import MCCYN from './MCCYN';
import NavyHCC from './NavyHCC';
import AirForceHCC from './AirForceHCC';
import { PROVIDER_APPLICATION_PROGRAM_TYPES } from '../SelectProgram/SelectProgram';
import { Spin } from 'antd';

const ApplicationProgramWrapper = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState(null);

  useEffect(() => {
    setLoading(true);
    providerService
      .getApplication(id)
      .then((data) => {
        setApplication(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const renderComponent = (programType) => {
    switch (programType.id) {
      case PROVIDER_APPLICATION_PROGRAM_TYPES.MCCYN.id:
        return <MCCYN application={application} setApplication={setApplication} />;
      case PROVIDER_APPLICATION_PROGRAM_TYPES.AIR_FORCE_HCC.id:
        return <AirForceHCC application={application} setApplication={setApplication} />;
      case PROVIDER_APPLICATION_PROGRAM_TYPES.NAVY_HCC.id:
        return <NavyHCC application={application} setApplication={setApplication} />;
      case PROVIDER_APPLICATION_PROGRAM_TYPES.ARMY_RESPITE.id:
        return <ArmyRespite application={application} setApplication={setApplication} />;
      default:
        return <div>Invalid Program Type</div>;
    }
  };

  return (
    <Spin size="large" spinning={loading}>
      {application && renderComponent(application.programType)}
    </Spin>
  );
};

export default ApplicationProgramWrapper;

export const isApplicationSubmitted = (application) =>
  application?.status?.status?.id === 2 || application?.status?.status?.id === 3;
