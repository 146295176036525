import React, { useState } from 'react';
import { Button, message, Spin, Typography, Alert } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectOrganization } from 'features/auth';
import Card from 'components/Card/Card';
import { Add } from '@carbon/icons-react';
import PageMeta from 'components/PageMeta/PageMeta';
import { providerService } from 'services';
import { useAsync } from 'react-async-hook';
import { PROVIDER_APPLICATION_PROGRAM_TYPES } from '../SelectProgram/SelectProgram';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import FormattedAddress from 'components/FormattedAddress/FormattedAddress';
import { EMDASH } from 'constants';

const { Paragraph, Title } = Typography;

const APPLICATION_STATUS_MAPPING = {
  INCOMPLETE: 'In progress',
  PENDING: 'Submitted',
};

const getApplicationStatusDescription = (application) => {
  return (
    application?.status?.status?.publicStatus?.description ||
    APPLICATION_STATUS_MAPPING[application?.status?.status?.title] ||
    application?.status?.status?.description ||
    EMDASH
  );
};

const ApplicationStatus = ({ application }) => {
  const getStatusClassNames = (description) => {
    return description?.toLowerCase() === APPLICATION_STATUS_MAPPING.INCOMPLETE.toLocaleLowerCase()
      ? 'text-[#000] font-semibold bg-[#FFF1B8] border border-solid border-[#FFC53D] py-1 px-2 rounded table'
      : 'text-[#000] font-semibold ';
  };

  const description = getApplicationStatusDescription(application);

  return <Paragraph className={getStatusClassNames(description)}>{description}</Paragraph>;
};

export default function Applications() {
  const history = useHistory();
  const organization = useSelector(selectOrganization);

  const [careFacilities, setCareFacilities] = useState(null);
  const [inProgressCount, setInProgressCount] = useState(0);

  const sortApplicationsByDescription = (a, b) => {
    const descriptionA = getApplicationStatusDescription(a)?.toLowerCase();
    const descriptionB = getApplicationStatusDescription(b)?.toLowerCase();
    const compareText = APPLICATION_STATUS_MAPPING.INCOMPLETE.toLocaleLowerCase();

    if (descriptionA === compareText) return -1;
    if (descriptionB === compareText) return 1;

    return descriptionA.localeCompare(descriptionB);
  };

  useAsync(async () => {
    try {
      const _careFacilities = await providerService.getCareFacilities(organization.id);
      let progressCount = 0;

      // Determine if each care facility profile is complete
      const _careFacilityProfileValidities = await Promise.all(
        _careFacilities?.content.map((careFacility) => providerService.getCareFacilityProfileValidity(careFacility.id)),
      );

      // fetch all the care facility applications, only if the profile is complete
      const _applications = await Promise.all(
        _careFacilities?.content.map((careFacility, index) => {
          if (_careFacilityProfileValidities?.[index]?.profileComplete === true) {
            return providerService.getCarefacilityApplications(careFacility.id);
          }

          return [];
        }),
      );

      // attach applications to care facilities
      _careFacilities?.content.forEach((careFacility, index) => {
        careFacility.applications = _applications[index];
        //Sort applications by description
        careFacility.applications?.sort(sortApplicationsByDescription);
        //Count inProgress applications
        progressCount +=
          careFacility.applications?.filter(
            (a) =>
              getApplicationStatusDescription(a)?.toLowerCase() ===
              (APPLICATION_STATUS_MAPPING.INCOMPLETE?.toLocaleLowerCase() || ''),
          ).length || 0;
        careFacility.profileValid = _careFacilityProfileValidities?.[index]?.profileComplete;
      });

      setCareFacilities(_careFacilities?.content);
      setInProgressCount(progressCount);
    } catch (error) {
      console.log('Error fetching care facilities', error);
      message.error('Failed to fetch care facilities');
    }
  }, [organization.id]);

  return (
    <div className="layout-page">
      <PageMeta title="My Applications" />

      <div id="page-top-placeholder" />

      <Spin spinning={!careFacilities}>
        {Array.isArray(careFacilities) && (
          <Card
            noBodyPadding
            title={
              <>
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <Title level={2} role="heading" aria-level={1} className="!mb-0 mt-6">
                      Locations and programs
                    </Title>

                    <Paragraph className="block !mb-4">
                      Manage your child care locations and program participation for fee assistance programs.
                    </Paragraph>
                  </div>
                  <div className="flex space-x-3">
                    <Button
                      icon={<Add size={20} className="ml-[-8px] mr-[-2px]" />}
                      onClick={() => history.push('/provider/facility')}
                      data-testid="add-location-cta"
                    >
                      Add location
                    </Button>
                  </div>
                </div>
                {inProgressCount > 0 && (
                  <div className="mb-6">
                    <Alert
                      message={`You have ${inProgressCount} ${
                        inProgressCount === 1 ? 'incomplete application' : 'incomplete applications'
                      }.`}
                      type="warning"
                      showIcon
                      className="bg-[#FFF1B8] border-[#FFC53D] font-normal"
                    />
                  </div>
                )}
              </>
            }
          >
            <div className="flex-col divide-y-[1px] divide-solid divide-[#c8c8c8] border-t border-solid border-t-[#c8c8c8]">
              {careFacilities?.length !== 0 &&
                careFacilities.map((careFacility, index) => {
                  return (
                    <div
                      key={careFacility.id}
                      className="p-6 flex"
                      style={{
                        backgroundColor: index % 2 === 0 ? '#FAFAFA' : undefined,
                      }}
                      data-testid={`care-facility-${careFacility.id}`}
                    >
                      <div className="flex-grow w-1/2">
                        <Title level={4} role="heading" aria-level={2}>
                          {careFacility.displayName || '—'}
                        </Title>
                        <Paragraph>
                          {careFacility.address ? <FormattedAddress address={careFacility.address} /> : 'N/A'}
                        </Paragraph>

                        {careFacility.profileValid ? (
                          <>
                            <Link to={`/provider/facility/${careFacility.id}`} className="table mt-7">
                              <Button>View location</Button>
                            </Link>

                            {Array.isArray(careFacility.applications) && careFacility.applications.length > 0 && (
                              <Link
                                to={`/provider/${careFacility.id}/add-application`}
                                className="table mt-7 underline"
                              >
                                Apply for another program
                              </Link>
                            )}
                          </>
                        ) : (
                          <Link
                            to={`/provider/facility/${careFacility.id}`}
                            className="table mt-7"
                            data-testid="finish-adding-location"
                          >
                            <Button type="primary">Finish adding location</Button>
                          </Link>
                        )}
                      </div>

                      {careFacility.profileValid && (
                        <>
                          {Array.isArray(careFacility.applications) && careFacility.applications.length > 0 ? (
                            <div className="flex-grow w-1/2">
                              <div className="flex flex-col divide-y divide-solid divide-[#c8c8c8]">
                                {careFacility?.applications?.map((application) => {
                                  const _programTypeName = Object.values(PROVIDER_APPLICATION_PROGRAM_TYPES).find(
                                    (programType) => programType.id === application.programType.id,
                                  )?.name;

                                  return (
                                    <div key={application.id} className="flex justify-between items-center">
                                      <div className="pt-4 pb-1">
                                        <Title
                                          level={5}
                                          role="heading"
                                          aria-level={3}
                                          className="font-bold"
                                          style={{
                                            color: 'rgba(0, 0, 0, 0.85)',
                                          }}
                                        >
                                          {_programTypeName || '—'}
                                        </Title>
                                        <ApplicationStatus application={application} />
                                      </div>

                                      <div>
                                        <Link
                                          to={`/provider/applications/${application.id}`}
                                          className="underline"
                                          data-testid="application-cta"
                                        >
                                          {application?.status?.status?.title === 'INCOMPLETE' ? (
                                            <Button type="primary">Resume application</Button>
                                          ) : (
                                            'View application'
                                          )}
                                        </Link>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : (
                            <Link
                              to={`/provider/${careFacility.id}/add-application`}
                              className="table mt-7 underline"
                              data-testid="apply-for-fee-assistance"
                            >
                              <Button type="primary">Apply for fee assistance</Button>
                            </Link>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          </Card>
        )}
      </Spin>
    </div>
  );
}
